import React, { useMemo, useRef } from "react";
import {
    Container,
    Row,
    Col,
    Input
} from "reactstrap";
import Button from 'react-bootstrap/Button';

import CheckIcon from "../Icons/CheckIcon";
import WaitIcon from "../Icons/WaitIcon";
import DeclinedIcon from "../Icons/DeclinedIcon";
import { useQuery, useMutation } from "@tanstack/react-query";

import axios from "axios";
// import toastr from "toastr";
import { toast } from 'react-toastify';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import { Skeleton } from 'antd';
import useAuthStore from "../profileStore";
import ContractPrint from "./ContractPrint";
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';

import "./style.css"

const translateDocument = (docName) => {
    const translations = {
        TC_photo: "TC Kimlik Fotoğrafı",
        tax_board: "Vergi Levhası",
        activity_certificate: "Faaliyet Belgesi",
        trade_registry_gazette: "Ticaret Sicil Gazetesi",
        authorized_signatures: "Imza sirküleri",
        smart_ewallet_contract: "AKILLI E-CÜZDAN SÖZLEŞMESİ",
        seller_contract: "BUTİKO SATICI İŞ ORTAKLIĞI ve İLAN SÖZLEŞMESİ"
    };

    return translations[docName] || "Belge adı bulunamadı"; // Default message if the docName doesn't match
};
const Documents = ({ type }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const key = "uploadDoc"
    const downloadKey = "downloadDoc"
    const printRef = useRef();
    const history = useNavigate();
    const toastRef = useRef(null);

    const [TCPhoto, setTCPhoto] = useState("");
    const [taxBoard, setTaxBoard] = useState("");
    const [activityCertificate, setActivityCertificate] = useState("");
    const [tradeRegistry, setTradeRegistry] = useState("");
    const [authorizedSignatures, setAuthorizedSignatures] = useState("");
    const [smartEwallet, setSmartEwallet] = useState("");
    const [sellerContract, setSellerContract] = useState("");
    const [isDocUploading, setIsDocUploading] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const refreshToken = useAuthStore(state => state.getRefreshToken)
    const statusTranslation = (status) => {
        if (status === "pending") {
            return "Bekliyor"
        } else if (status === "approved") {
            return "Onaylandı"
        } else if (status === "declined") {
            return "Reddedildi"
        } else {
            return "Yüklenmedi"
        }
    }
    const location = useLocation();
    const state = location.state || {}; // Default to an empty object if state is undefined
    // console.log(state);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        removeAfterPrint: true
    });
    const getContractData = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/orders/get-partnership-contract-data`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const getDocInfo = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/documents-information`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { isLoading, isError, data, error, isSuccess, refetch } = useQuery({ queryKey: ['get-doc-info'], queryFn: getDocInfo })
    //start contract info data
    const {
        isLoading: contractDataIsLoading,
        isError: contractDataIsError,
        data: contractData,
        error: contractDataError
    } = useQuery({ queryKey: ["get-contract-data"], queryFn: getContractData })
    if (contractDataIsLoading) {

    } else if (contractDataIsError) {
        console.log(contractDataError);
        toast.error("Veri alınırken hata oluştu", { toastId: "contract-data-error" })
    } else {
        // console.log(contractData);
    }


    //end contract info
    if (isLoading) {

    } else if (isError) {
        if (error?.response?.data?.detail[0]?.msg === "unathorized action" || error?.response?.data?.detail[0]?.msg === "invalid jwt") {
            history("/login")
        }
        console.log(error);

    } else {
        // console.log(data.data);

        if (data?.data?.is_seller_approved === 1 && state === "redirect") {
            refreshToken()
            // setTimeout(() => {

            //     location.reload();
            // }, 300);

            // console.log("refetchsdfsdfsdfsd");

        }
    }


    const fileUpload = (type) => {
        const finalUrl =
            `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/profile/upload-file?file_type=${type}`;
        const finalData = new FormData();
        switch (type) {
            case 'tax_board':
                finalData.append("file_data", taxBoard);
                break
            case 'activity_certificate':
                finalData.append("file_data", activityCertificate);
                break
            case 'trade_registry_gazette':
                finalData.append("file_data", tradeRegistry);
                break
            case 'authorized_signatures':
                finalData.append("file_data", authorizedSignatures);
                break
            case 'smart_ewallet_contract':
                finalData.append("file_data", smartEwallet);
                break
            case 'seller_contract':
                finalData.append("file_data", sellerContract);
                break
            default:
                finalData.append("file_data", TCPhoto);
                break

        }


        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const fileUploadMutation = useMutation({
        mutationFn: fileUpload,

        onSuccess: () => {
            // toast.success("İşlem Tamamlandı", { toastId: "fileUploadMutation" })
            // toast.update(toastRef.current, { render: "Yüklendi👌", type: "success", isLoading: false, autoClose: 1000 })
            messageApi.open({
                key,
                type: 'success',
                content: 'Belge başarıyla yüklendi👌',
                duration: 2,
            });
            setIsDocUploading(false)
            refetch()
        },
        onError: (error) => {
            setIsDocUploading(false)
            if (error.response.data.detail[0].msg === "unathorized action" || error.response.data.detail[0].msg === "invalid jwt") {
                history("/login")
            }
            else if (error.response.data.detail[0].type === "value_error") {
                // toast.update(toastRef.current, { render: "lütfen bir dosya yükleyin", toastId: "fileUploadMutationError", type: "error", isLoading: false, autoClose: 1000 })
                toast.error("lütfen bir dosya yükleyin", { toastId: "fileUploadMutationError" })
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'lütfen bir dosya yükleyin',
                    duration: 2,
                });
            } else if (error.response.data.detail[0].msg === "file size above maxiumum") {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Maksimum dosya boyutu: 5Mb',
                    duration: 2,
                });
                // toast.update(toastRef.current, { render: "Maksimum dosya boyutu: 5Mb", type: "error", isLoading: false, autoClose: 2000 })
            } else if (error.response.data.detail[0].msg === "unsupported mime type") {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Desteklenmeyen dosya türü',
                    duration: 2,
                });
                // toast.update(toastRef.current, { render: "Desteklenmeyen dosya türü", type: "error", isLoading: false, autoClose: 2000 })
            } else {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Yüklenmedi',
                    duration: 2,
                });
                // toast.update(toastRef.current, { render: "Yüklenmedi", type: "error", isLoading: false, autoClose: 1000 })
            }
        },
    })
    const downloadDoc = async (type) => {
        const a = document.createElement('a');
        const fileUrl = data?.data[type]
        console.log(fileUrl);
        if (fileUrl) {
            a.href = fileUrl;
            a.target = "_blank";
            a.download = translateDocument(type) || 'download';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            messageApi.open({
                type: 'warning',
                content: 'Dosya Yok!',
            });
        }

        // try {
        //     const fileUrl = data?.data[type]
        //     const response = await axios.get(fileUrl, {
        //         withCredentials: true,
        //         responseType: "blob"
        //     })
        //     const mimeType = response.headers['content-type'];
        //     const file = new Blob([response.data], { type: mimeType })
        //     const fileUrlObject = URL.createObjectURL(file)
        //     window.open(fileUrlObject, "_blank")
        // } catch (error) {
        //     if (data?.data[type] === null) {
        //         toast.error("dosya yok", { toastId: "noFile" })
        //     }
        //     console.log(error);
        //     toast.error("Hata", { toastId: "downloadError" })
        // }
    }
    useMemo(() => {
        setCompanyAddress(contractData?.data?.office_address)
        setCompanyName(contractData?.data?.company_title)
    }, [contractData])
    return (
        <>
            {isLoading ? (<Skeleton />) : (<Container>
                {type === "company" ? (
                    <div>
                        <Row>
                            <Col className="text-start" xs="12" sm="12" md="3" >
                                <Row>
                                    <Col>
                                        <span>T.C. Kimlik</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_TC_photo === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_TC_photo === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_TC_photo === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span

                                            className={
                                                data?.data?.status_TC_photo === "pending"
                                                    ? "text-warning"
                                                    : data?.data?.status_TC_photo === "approved"
                                                        ? "text-success"
                                                        : "text-danger"
                                            }
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_TC_photo)}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            {data?.data?.status_TC_photo === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (
                                <>

                                    <Col className="p-0" xs="9" sm="9" md="7">
                                        <Input
                                            id="exampleFile"
                                            accept=".pdf, .jpg, .png"
                                            name="file"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files[0] !== undefined) {
                                                    setTCPhoto(e.target.files[0]);
                                                    setIsDocUploading(true)
                                                    fileUploadMutation.mutate("TC_photo")

                                                    messageApi.open({
                                                        key,
                                                        type: 'loading',
                                                        content: 'Belge yükleniyor...',
                                                        duration: 0,

                                                    });
                                                    // e.target.value = ''
                                                }
                                            }}
                                        />
                                    </Col>

                                </>
                            )}
                            <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_TC_photo !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }

                                    onClick={() => {
                                        downloadDoc("TC_photo")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="button">

                                    İndir
                                </Button>
                            </Col>
                        </Row>
                        <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                        <Row>
                            <Col className="text-start" xs="12" sm="12" md="3">
                                <Row>
                                    <Col>
                                        <span>
                                            Vergi levhası
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_tax_board === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_tax_board === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_tax_board === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span
                                            className={
                                                data?.data?.status_tax_board === "pending" ?
                                                    "text-warning" :
                                                    data?.data?.status_tax_board === "approved" ?
                                                        "text-success" : "text-danger"
                                            }
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_tax_board)}
                                        </span>
                                    </Col>
                                </Row>

                            </Col>
                            {data?.data?.status_tax_board === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (<>

                                <Col className="p-0" xs="9" sm="9" md="7">
                                    <Input
                                        id="exampleFile"
                                        accept=".pdf, .jpg, .png"
                                        name="file"
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                                setTaxBoard(e.target.files[0]);
                                                setIsDocUploading(true)
                                                fileUploadMutation.mutate("tax_board")

                                                messageApi.open({
                                                    key,
                                                    type: 'loading',
                                                    content: 'Belge yükleniyor...',
                                                    duration: 0,
                                                });
                                                // e.target.value = ''
                                            }
                                        }}
                                    />
                                </Col>
                            </>)}
                            <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_tax_board !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }

                                    onClick={() => {
                                        downloadDoc("tax_board")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="submit"
                                >

                                    İndir
                                </Button>
                            </Col>
                        </Row>
                        <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                        <Row>
                            <Col className="text-start" xs="12" sm="12" md="3">
                                <Row>
                                    <Col>
                                        <span>
                                            Faaliyet belgesi
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_activity_certificate === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_activity_certificate === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_activity_certificate === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span
                                            className={
                                                data?.data?.status_activity_certificate === "pending" ?
                                                    "text-warning" :
                                                    data?.data?.status_activity_certificate === "approved"
                                                        ? "text-success"
                                                        : "text-danger"
                                            }
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_activity_certificate)}
                                        </span>

                                    </Col>
                                </Row>
                            </Col>

                            {data?.data?.status_activity_certificate === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (
                                <>

                                    <Col className="p-0" xs="9" sm="9" md="7">
                                        <Input
                                            id="exampleFile"
                                            accept=".pdf, .jpg, .png"
                                            name="file"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files[0] !== undefined) {
                                                    setActivityCertificate(e.target.files[0]);
                                                    setIsDocUploading(true)
                                                    fileUploadMutation.mutate("activity_certificate")

                                                    messageApi.open({
                                                        key,
                                                        type: 'loading',
                                                        content: 'Belge yükleniyor...',
                                                        duration: 0,
                                                    });
                                                    // e.target.value = ""
                                                }
                                            }}
                                        />
                                    </Col>
                                </>
                            )}
                            <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_activity_certificate !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }

                                    onClick={() => {
                                        downloadDoc("activity_certificate")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="submit"
                                >

                                    İndir
                                </Button>
                            </Col>
                        </Row>
                        <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                        <Row>
                            <Col className="text-start" xs="12" sm="12" md="3">
                                <Row>
                                    <Col>
                                        <span>
                                            Ticaret sicil gazetesi
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_trade_registry_gazette === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_trade_registry_gazette === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_trade_registry_gazette === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span className={data?.data?.status_trade_registry_gazette === "pending" ? "text-warning" : data?.data?.status_trade_registry_gazette === "approved" ? "text-success" : "text-danger"} style={{ fontSize: "10px" }}>{statusTranslation(data?.data?.status_trade_registry_gazette)}</span>
                                    </Col>
                                </Row>
                            </Col>

                            {data?.data?.status_trade_registry_gazette === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (
                                <>

                                    <Col className="p-0" xs="9" sm="9" md="7">
                                        <Input
                                            id="exampleFile"
                                            name="file"
                                            accept=".pdf, .jpg, .png"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    setTradeRegistry(e.target.files[0]);
                                                    setIsDocUploading(true)
                                                    fileUploadMutation.mutate("trade_registry_gazette")
                                                    // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });
                                                    messageApi.open({
                                                        key,
                                                        type: 'loading',
                                                        content: 'Belge yükleniyor...',
                                                        duration: 0,
                                                    });
                                                    // e.target.value = ""
                                                }
                                            }}
                                        />
                                    </Col>
                                </>
                            )}
                            <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_trade_registry_gazette !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }
                                    // onClick={downloadTradeRegistry}
                                    onClick={() => {
                                        downloadDoc("trade_registry_gazette")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="submit"
                                >

                                    İndir
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Row>

                            <Col className="text-start" xs="12" sm="12" md="3" >
                                <Row>
                                    <Col>
                                        <span>T.C. Kimlik</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_TC_photo === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_TC_photo === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_TC_photo === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span className={data?.data?.status_TC_photo === "pending" ? "text-warning" : data?.data?.status_TC_photo === "approved" ? "text-success" : "text-danger"} style={{ fontSize: "10px" }}>{statusTranslation(data?.data?.status_TC_photo)}</span>
                                    </Col>
                                </Row>
                            </Col>
                            {data?.data?.status_TC_photo === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (
                                <>

                                    <Col className="p-0" xs="9" sm="9" md="7">
                                        <Input
                                            id="exampleFile"
                                            name="file"
                                            accept=".pdf, .jpg, .png"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files[0] !== undefined) {
                                                    setTCPhoto(e.target.files[0]);
                                                    setIsDocUploading(true)
                                                    fileUploadMutation.mutate("TC_photo")
                                                    // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });
                                                    messageApi.open({
                                                        key,
                                                        type: 'loading',
                                                        content: 'Belge yükleniyor...',
                                                        duration: 0,
                                                    });
                                                    // e.target.value = ""
                                                }
                                            }}
                                        />
                                    </Col>

                                </>
                            )}
                            <Col xs="6" sm="6" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_TC_photo !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }
                                    // onClick={downloadTC}
                                    onClick={() => {
                                        downloadDoc("TC_photo")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="submit"
                                >

                                    İndir
                                </Button>
                            </Col>

                        </Row>
                        <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                        <Row>
                            <Col className="text-start" xs="12" sm="12" md="3">
                                <Row>
                                    <Col>
                                        <span>
                                            Vergi levhası
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_tax_board === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_tax_board === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_tax_board === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span
                                            className={
                                                data?.data?.status_tax_board === "pending" ?
                                                    "text-warning" :
                                                    data?.data?.status_tax_board === "approved" ?
                                                        "text-success" : "text-danger"
                                            }
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_tax_board)}
                                        </span>
                                    </Col>
                                </Row>

                            </Col>
                            {data?.data?.status_tax_board === "approved" ? (
                                <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                                    <span>
                                        Dosya yüklenmiştir
                                    </span>
                                </Col>
                            ) : (<>

                                <Col className="p-0" xs="9" sm="9" md="7">
                                    <Input
                                        id="exampleFile"
                                        name="file"
                                        accept=".pdf, .jpg, .png"
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files[0] !== undefined) {
                                                setTaxBoard(e.target.files[0]);
                                                setIsDocUploading(true)
                                                fileUploadMutation.mutate("tax_board")
                                                // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });
                                                messageApi.open({
                                                    key,
                                                    type: 'loading',
                                                    content: 'Belge yükleniyor...',
                                                    duration: 0,
                                                });
                                                // e.target.value = ""
                                            }
                                        }}
                                    />
                                </Col>
                            </>)}

                            <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                                <Button
                                    style={
                                        data?.data?.status_tax_board !== "approved" ?
                                            ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " }) :
                                            ({})
                                    }
                                    // onClick={downloadTaxBoard}
                                    onClick={() => {
                                        downloadDoc("tax_board")
                                    }}
                                    color="primary"
                                    variant="link"
                                    type="submit"
                                >

                                    İndir
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}

                <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                <Row className="d-flex align-items-center">
                    <Col className="text-start" xs="12" sm="12" md="3">
                        <Row>
                            <Col>
                                <span>
                                    Imza sirküleri
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="1">
                                {data?.data?.status_authorized_signatures === "pending" ?
                                    (<WaitIcon />) :
                                    data?.data?.status_authorized_signatures === "approved" ?
                                        (<CheckIcon />) :
                                        data?.data?.status_tax_board === "declined" ?
                                            (<DeclinedIcon />) :
                                            (<DeclinedIcon />)
                                }

                            </Col>
                            <Col>
                                <span
                                    className={data?.data?.status_authorized_signatures === "pending" ?
                                        "text-warning" :
                                        data?.data?.status_authorized_signatures === "approved" ?
                                            "text-success" :
                                            "text-danger"}
                                    style={{ fontSize: "10px" }}
                                >{statusTranslation(data?.data?.status_authorized_signatures)}
                                </span>
                            </Col>
                        </Row>

                    </Col>
                    {data?.data?.status_authorized_signatures === "approved" ? (
                        <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                            <span>
                                Dosya yüklenmiştir
                            </span>
                        </Col>
                    ) : (
                        <>

                            <Col className="p-0" xs="9" sm="9" md="7">
                                <Input
                                    id="exampleFile"
                                    name="file"
                                    accept=".pdf, .jpg, .png"
                                    type="file"
                                    onChange={(e) => {
                                        if (e.target.files[0] !== undefined) {
                                            setAuthorizedSignatures(e.target.files[0]);
                                            setIsDocUploading(true)
                                            fileUploadMutation.mutate("authorized_signatures")
                                            // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });
                                            messageApi.open({
                                                key,
                                                type: 'loading',
                                                content: 'Belge yükleniyor...',
                                                duration: 0,
                                            });
                                            // e.target.value = ""
                                        }
                                    }}
                                    style={{ borderRadius: "0px" }}
                                />
                            </Col>
                        </>
                    )}
                    <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                        <Button
                            style={
                                data?.data?.status_authorized_signatures !== "approved" ?
                                    ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " })
                                    : ({})
                            }
                            color="primary"
                            // onClick={downloadSignatures}
                            variant="link"
                            onClick={() => {
                                downloadDoc("authorized_signatures")
                            }}
                            type="submit"
                        >

                            İndir
                        </Button>
                    </Col>
                </Row>
                <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                <Row className="d-flex align-items-center">
                    <Col xs="12" sm="12" md="3">
                        <Row>
                            {/* <Col xs="1">
                            
                        </Col> */}
                            <Col className="text-start" >
                                <Row>
                                    <Col>
                                        <a
                                            id="smart_ewallet_contract"
                                            href="https://butikoexpress.com/BUTIKO%20E-CUZDAN%20KULLANICI%20SO%CC%88ZLES%CC%A7MESI%CC%87.pdf"
                                            download
                                            className="text-danger text-decoration-underline"
                                        >
                                            <i className='bx bx-download text-danger me-2 ' ></i>
                                            AKILLI E-CÜZDAN SÖZLEŞMESİ
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span style={{ color: "#B0B0B0" }}>
                                            Lütfen tüm sayfaları kaşe ve imza yapatıktan sonra tarayıp yükleyiniz
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="1">
                                        {
                                            data?.data?.status_smart_ewallet_contract === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_smart_ewallet_contract === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_smart_ewallet_contract === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }
                                    </Col>
                                    <Col>
                                        <span
                                            className={data?.data?.status_smart_ewallet_contract === "pending" ?
                                                "text-warning" :
                                                data?.data?.status_smart_ewallet_contract === "approved" ?
                                                    "text-success" : "text-danger"}
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_smart_ewallet_contract)}
                                        </span>
                                    </Col>
                                </Row>


                            </Col>
                        </Row>
                    </Col>
                    {data?.data?.status_smart_ewallet_contract === "approved" ? (
                        <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                            <span>
                                Dosya yüklenmiştir
                            </span>
                        </Col>
                    ) : (
                        <>

                            <Col className="p-0" xs="9" sm="9" md="7">
                                <Input
                                    id="exampleFile"
                                    name="file"
                                    accept=".pdf, .jpg, .png"
                                    type="file"
                                    onChange={(e) => {
                                        if (e.target.files[0] !== undefined) {

                                            setIsDocUploading(true)
                                            setSmartEwallet(e.target.files[0]);
                                            fileUploadMutation.mutate("smart_ewallet_contract")
                                            // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading", autoClose: 5000 });
                                            messageApi.open({
                                                key,
                                                type: 'loading',
                                                content: 'Belge yükleniyor...',
                                                duration: 0,
                                            });
                                            // e.target.value = ""
                                        }
                                    }}
                                />
                            </Col>
                        </>
                    )}
                    <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                        <Button
                            style={
                                data?.data?.status_smart_ewallet_contract !== "approved" ?
                                    ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " })
                                    : ({})
                            }
                            // onClick={downloadEWallet}
                            onClick={() => {
                                if (data?.data?.status_smart_ewallet_contract !== "approved") {
                                    const element = document.getElementById("smart_ewallet_contract");
                                    if (element) {
                                        element.click();
                                    } else {
                                        console.warn("Element with ID 'smart_ewallet_contract' not found.");
                                    }
                                } else {
                                    downloadDoc("smart_ewallet_contract")
                                }
                            }}
                            color="primary"
                            variant="link"
                            type="button"
                        >

                            İndir
                        </Button>
                    </Col>
                </Row>
                <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} />
                <Row className="d-flex align-items-center">
                    <Col xs="12" sm="12" md="3">
                        <Row>
                            <Col className="text-start">
                                <Row >
                                    <Col >
                                        <a
                                            // href={`${process.env.PUBLIC_URL}/BUTİKO_SATICI_İŞ_ORTAKLIĞI_ve_İLAN_SÖZLEŞMESİ.docx`}
                                            // download="BUTİKO_SATICI_İŞ_ORTAKLIĞI_ve_İLAN_SÖZLEŞMESİ.docx"
                                            onClick={() => {
                                                if (companyAddress && companyName) {
                                                    handlePrint()
                                                } else {
                                                    toast.error("Veri alınırken hata oluştu")
                                                }

                                            }}
                                            className="text-danger text-decoration-underline"
                                        >
                                            <i className='bx bx-download text-danger me-2 ' ></i>
                                            BUTİKO SATICI İŞ ORTAKLIĞI ve İLAN SÖZLEŞMESİ
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span style={{ color: "#B0B0B0" }}>
                                            Lütfen tüm sayfaları kaşe ve imza yapatıktan sonra tarayıp yükleyiniz
                                        </span>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs="1">
                                        {
                                            data?.data?.status_seller_contract === "pending" ?
                                                (<WaitIcon />) :
                                                data?.data?.status_seller_contract === "approved" ?
                                                    (<CheckIcon />) :
                                                    data?.data?.status_seller_contract === "declined" ?
                                                        (<DeclinedIcon />) :
                                                        (<DeclinedIcon />)
                                        }

                                    </Col>
                                    <Col>
                                        <span
                                            className={
                                                data?.data?.status_seller_contract === "pending" ?
                                                    "text-warning" :
                                                    data?.data?.status_seller_contract === "approved" ?
                                                        "text-success" : "text-danger"}
                                            style={{ fontSize: "10px" }}
                                        >
                                            {statusTranslation(data?.data?.status_seller_contract)}
                                        </span>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    {data?.data?.status_seller_contract === "approved" ? (
                        <Col className="d-flex justify-content-center align-items-center " md="7" xs="5">
                            <span>
                                Dosya yüklenmiştir
                            </span>
                        </Col>
                    ) : (
                        <>

                            <Col className="p-0" xs="9" sm="9" md="7">
                                <Input
                                    id="exampleFile"
                                    accept=".pdf, .jpg, .png"
                                    name="file"
                                    type="file"
                                    onChange={(e) => {
                                        if (e.target.files[0] !== undefined) {
                                            setSellerContract(e.target.files[0]);
                                            setIsDocUploading(true)
                                            fileUploadMutation.mutate("seller_contract")
                                            // toastRef.current = toast.loading("Resim yükleniyor...", { toastId: "loading",autoClose: 5000 });
                                            messageApi.open({
                                                key,
                                                type: 'loading',
                                                content: 'Belge yükleniyor...',
                                                duration: 0,
                                            });
                                            // e.target.value = ""
                                        }
                                    }}
                                />
                            </Col>
                        </>
                    )}
                    <Col xs="3" sm="3" md="2" className="d-flex flex-column align-items-start p-0">
                        <Button
                            style={
                                data?.data?.status_seller_contract !== "approved" ?
                                    ({ backgroundColor: "#EFF2F7", color: "#495057", borderColor: "#ced4da", fontWeight: "400", borderRadius: "0 5px 5px 0 " })
                                    : ({})
                            }
                            // onClick={downloadSellerContract}
                            onClick={() => {
                                if (data?.data?.status_seller_contract !== "approved") {
                                    if (companyAddress && companyName) {
                                        handlePrint()
                                    } else {
                                        toast.error("Veri alınırken hata oluştu")
                                    }
                                } else {

                                    downloadDoc("seller_contract")
                                }
                            }}
                            color="primary"
                            variant="link"
                            type="submit"
                        >

                            İndir
                        </Button>
                    </Col>

                </Row>
                <div className=" show-print mx-3 " ref={printRef}>
                    <ContractPrint companyAddress={companyAddress} companyName={companyName} />

                </div>
                {/* <hr className="my-2" style={{ borderTop: "3px solid #EFF2F7" }} /> */}
            </Container>
            )}
            {contextHolder}
        </>
    )
}

export default Documents