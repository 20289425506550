import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { get, post } from "helpers/api_helper";
import * as url from "helpers/url_helper";
import PendingContractsModal from "../pages/Contracts/components/PendingContractsModal"; 
import useUserStore from "./routeStore";

const Authmiddleware = (props) => {
  const history = useNavigate();
  const [modal, setModal] = useState(false);  
  const toggleModal = () => setModal(!modal); 
  let location = useLocation();

  const isAuth = () => {
    return get(url.GET_IS_AUTH);
  };

  const { isLoading, isError, error, data } = useQuery({ queryKey: ["is-auth"], queryFn: isAuth });

  let status;
  if (isLoading) {
    status = "Checking your authentication...";
  } else if (isError) {
    console.error("Error checking authentication:", error);
  } else {
    status = data;
    if (
      (location.pathname !== "/profile" && location.pathname !== "/dashboard") &&
      status?.payload?.approval_status === "pending"
    ) {
      history("/profile", { state: "redirect" });
      toast.info("Kullanıcı kaydınız tamamlanmadan işlem yapamazsınız", { toastId: "warning" });
    } else if (!status?.is_authenticated) {
      history("/");
    }
  }

  const checkPendingContracts = async () => {
    try {
      const response = await get(`${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/contracts/check-pending-contracts`,{ withCredentials: true });
      console.log("API response:", response); 
      if (response?.has_pending_contract) {
        console.log("Pending contract var, modal açılıyor..."); 
        setModal(true); 
      } else {
        console.log("Pending contract yok.");
      }
    } catch (error) {
      console.error("Sözleşme kontrolünde hata:", error);
    }
};


useEffect(() => {
  const interval = setInterval(() => {
    checkPendingContracts(); 
  }, 3600000); 

  return () => clearInterval(interval); 
}, []);


  return (
    <React.Fragment>
      {props.children}
      <PendingContractsModal isOpen={modal} toggle={toggleModal} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Authmiddleware;
